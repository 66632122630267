import type { Product, ProductCardExtended } from '#root/api/clients/product/data-contracts'

/**
 * Returns if the current product has coming soon enabled via badge or eyebrow text
 *
 * @param {Product | ProductCardExtended} product - The product object
 * @example
 * getIsComingSoonProduct(product.value, $feature.showComingSoonOnPDP)
 * returns true
 */
export const getIsComingSoonProduct = (product: Product | ProductCardExtended | null): boolean =>
  !!product && [product.badge?.id?.toUpperCase(), product.eyebrow?.id?.toUpperCase()].includes('COMING SOON')
